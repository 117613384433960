/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/packs and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_packs_with_chunks_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import 'core-js/stable'
// import 'regenerator-runtime/runtime'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")
import 'channels'



import resolveConfig from 'tailwindcss/resolveConfig'
import myConfig from '../css/tailwind'
const tailwindConfig = resolveConfig(myConfig)
window.colors = tailwindConfig.theme.colors

// import 'jquery'
import 'css/application.scss'
import "chartkick/chart.js"

// // Fontawesome
// import { config, library, dom } from '@fortawesome/fontawesome-svg-core'
// // Change the config to fix the flicker
// config.mutateApproach = 'sync'
// // Import icons
// import { faInfoCircle, faCheckCircle, faExclamationCircle, faExclamationTriangle, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
// library.add(faInfoCircle, faCheckCircle, faExclamationCircle, faExclamationTriangle, faExternalLinkAlt)
// // Load icons
// dom.watch()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)



import 'alpinejs'

import tippy from 'tippy.js'
window.tippy = tippy;
window.$ = window.jQuery = require("jquery");

// import 'bootstrap/js/src/alert'
// import 'bootstrap/js/src/button'
// import 'bootstrap/js/src/carousel'
// import 'bootstrap/js/src/collapse'
// import 'bootstrap/js/src/dropdown'
// import 'bootstrap/js/src/modal'
// import 'bootstrap/js/src/popover'
// import 'bootstrap/js/src/scrollspy'
// import 'bootstrap/js/src/tab'
// import 'bootstrap/js/src/toast'
// import 'bootstrap/js/src/tooltip'
import Popover from "bootstrap/js/dist/popover"
window.Popover = Popover
// import Tooltip from "bootstrap/js/dist/tooltip"
// window.Tooltip = Tooltip
//
import bootstrap from "bootstrap/dist/js/bootstrap"
window.bootstrap = bootstrap
// import "../js/sweetalert"
import '../js/site'
import '../js/youtube'

import select2 from "select2"
import "../js/select2"

import "controllers"

window.convertHex = function(hexCode, opacity = 1){
  var hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  var r = parseInt(hex.substring(0,2), 16),
    g = parseInt(hex.substring(2,4), 16),
    b = parseInt(hex.substring(4,6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return 'rgba('+r+','+g+','+b+','+opacity+')';
}
