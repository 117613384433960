import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["link"]

  connect() {
    // Controller setup
  }

  download(event) {
    event.preventDefault();
    const url = this.linkTarget.getAttribute('href');
    this.addSpinner();

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const disposition = response.headers.get('Content-Disposition');
        let filename = 'download.csv';
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        return response.blob().then(blob => ({ blob, filename }));
      })
      .then(({ blob, filename }) => {
        this.downloadFile(blob, filename);
      })
      .catch(error => console.error('Error:', error))
      .finally(() => this.removeSpinner());
  }

  addSpinner() {
    this.linkTarget.insertAdjacentHTML('beforeend', '<span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>');
  }

  removeSpinner() {
    const spinner = this.linkTarget.querySelector('.spinner-border');
    if (spinner) {
      spinner.remove();
    }
  }

  downloadFile(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
