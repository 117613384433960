// See the Tailwind default theme values here:
// https://github.com/tailwindcss/tailwindcss/blob/master/stubs/defaultConfig.stub.js
const colors = require('tailwindcss/colors')
const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  // Opt-in to TailwindCSS future changes
  future: {
  },

  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography'),
    require('@tailwindcss/aspect-ratio'),
    require('tailwindcss-font-inter')(),
    function({ addBase, theme }) {
      // With this plugin, tailwind colors are exposed as var(--color-color_name-intensity)
      // for example : var(--color-secondary-900)
      function extractColorVars(colorObj, colorGroup = '') {
        return Object.keys(colorObj).reduce((vars, colorKey) => {
          const value = colorObj[colorKey];

          const newVars =
              typeof value === 'string'
                  ? { [`--color${colorGroup}-${colorKey}`]: value }
                  : extractColorVars(value, `-${colorKey}`);

          return { ...vars, ...newVars };
        }, {});
      }

      addBase({
        ':root': extractColorVars(theme('colors')),
      });
    }
  ],

  // Purge unused TailwindCSS styles
  purge: {
    enabled: ["production", "staging"].includes(process.env.NODE_ENV),
    content: [
      './**/*.html.erb',
      './**/*.html.haml',
      './app/helpers/**/*.rb',
      './app/frontend/**/*.js',
    ],
  },

  // All the default values will be compiled unless they are overridden below
  theme: {
    // Extend (add to) the default theme in the `extend` key
    extend: {
      // Create your own at: https://javisperez.github.io/tailwindcolorshades
      colors: {
        //orange: colors.orange,
        'primary': {
          50:  '#F5F3FF',
          100: '#EDE9FE',
          200: '#DDD6FE',
          300: '#C4B5FD',
          400: '#A78BFA',
          500: '#8B5CF6',
          600: '#7C3AED',
          700: '#6D28D9',
          800: '#5B21B6',
          900: '#4C1D95',
        },
        'secondary': {
          50:  '#F9FAFB',
          100: '#F3F4F6',
          200: '#E5E7EB',
          300: '#D1D5DB',
          400: '#9CA3AF',
          500: '#6B7280',
          600: '#4B5563',
          700: '#374151',
          800: '#1F2937',
          900: '#111827',
        },
        'tertiary': {
          50: '#F7F7F8',
          100: '#EEEEF1',
          200: '#D5D5DB',
          300: '#BCBCC5',
          400: '#898A9A',
          500: '#57586E',
          600: '#4E4F63',
          700: '#343542',
          800: '#272832',
          900: '#1A1A21',
        },
        'danger': {
          50:  '#FEF2F2',
          100: '#FEE2E2',
          200: '#FECACA',
          300: '#FCA5A5',
          400: '#F87171',
          500: '#EF4444',
          600: '#DC2626',
          700: '#B91C1C',
          800: '#991B1B',
          900: '#7F1D1D',
        },
        "code-400": "#fefcf9",
        "code-600": "#3c455b",
      },
    },
    fontFeatureSettings: {
      numeric: ['tnum', 'salt', 'ss02']
    },
    fontSize: {
      xs:     ['0.75rem',   { lineHeight: '1rem',     letterSpacing: '0' }],
      sm:     ['0.875rem',  { lineHeight: '1.25rem',  letterSpacing: '-0.006em' }],
      base:   ['1rem',      { lineHeight: '1.5rem',   letterSpacing: '-0.011em' }],
      lg:     ['1.125rem',  { lineHeight: '1.75rem',  letterSpacing: '-0.013em' }],
      xl:     ['1.25rem',   { lineHeight: '1.75rem',  letterSpacing: '-0.013em' }],
      '2xl':  ['1.5rem',    { lineHeight: '2rem',     letterSpacing: '-0.017em' }],
      '3xl':  ['1.875rem',  { lineHeight: '2.25rem',  letterSpacing: '-0.019em' }],
      '4xl':  ['2.25rem',   { lineHeight: '2.5rem'  }],
      '5xl':  ['3rem',      { lineHeight: '1'       }],
      '6xl':  ['3.75rem',   { lineHeight: '1'       }],
      '7xl':  ['4.5rem',    { lineHeight: '1'       }],
      '8xl':  ['6rem',      { lineHeight: '1'       }],
      '9xl':  ['8rem',      { lineHeight: '1'       }],
    },
    // override the default theme using the key directly
    fontFamily: {
      sans: ['Inter', 'ui-sans-serif', ...defaultTheme.fontFamily.sans],
    },
  },
  variants: {
    borderWidth: ['responsive', 'last', 'hover', 'focus'],
  },
}
