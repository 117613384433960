"use strict";
$(function() {
    $(".youtube").each(function() {
        // Based on the YouTube ID, we can easily find the thumbnail image
        if ($(this).data('youtube-url').includes('playlist')) {
            $(this).css('background-image', 'url(https://images.holley.com/playlist/' + $(this).data('youtube-url').replace('https://www.youtube.com/embed/playlist?list=', '') + '.jpg)');
        } else {
            $(this).css('background-image', 'url(http://i.ytimg.com/vi/' + this.id + '/mqdefault.jpg)');
        }

        // Overlay the Play icon to make it look like a video player
        $(this).append($('<div/>', {'class': 'play'}));

        if (this.id !== '') {
            $(document).delegate('#'+this.id, 'click', function() {
                // Create an iFrame with autoplay set to true
                var iframe_url = "https://www.youtube.com/embed/" + this.id + "?autoplay=1&autohide=1";
                if ($(this).data('params')) iframe_url+='&'+$(this).data('params');

                // The height and width of the iFrame should be the same as parent
                var iframe = $('<iframe/>', {'frameborder': '0', 'src': iframe_url, 'width': $(this).width(), 'height': $(this).height() })

                // Replace the YouTube thumbnail with YouTube HTML5 Player
                $(this).replaceWith(iframe);
            });
        }

    });
});
