document.addEventListener("turbolinks:load", () => {

    const s2 = $('.select2-field');

    if (s2.length) {

        $(function () {
            return $('.select2-field.parent').select2({
                theme: 'bootstrap4',
                allowClear: true,
                placeholder: '',
                minimumInputLength: 3,
                maximumInputLength: 20,
                language: {
                    inputTooShort: function () {
                        return "Search Part Numbers...";
                    }
                },
                ajax: {
                    url: $('.select2-field.parent').data('endpoint'),
                    dataType: 'json',
                    delay: 250,
                    data: function (params) {
                        return {
                            q: params.term,
                            page: params.page
                        };
                    },
                    processResults: function (data) {
                        return {
                            results: $.map(data, function (item) {
                                return {
                                    text: item.part_number,
                                    id: item.id
                                };
                            })
                        };
                    }
                }
            });
        });
        $(function () {
            return $('.select2-field.customer').select2({
                theme: 'bootstrap4',
                allowClear: true,
                placeholder: '',
                minimumInputLength: 3,
                maximumInputLength: 20,
                language: {
                    inputTooShort: function () {
                        return "Search customer name or ID...";
                    }
                },
                ajax: {
                    url: $('.select2-field.customer').data('endpoint'),
                    dataType: 'json',
                    delay: 250,
                    data: function (params) {
                        return {
                            q: params.term,
                            page: params.page
                        };
                    },
                    processResults: function (data) {
                        return {
                            results: $.map(data, function (item) {
                                return {
                                    text: item.name,
                                    id: item.id
                                };
                            })
                        };
                    }
                }
            });
        });
        $(function () {
            return $('.select2-field.categories').select2({
                theme: 'bootstrap4',
                minimumInputLength: 3,
                maximumInputLength: 20,
                ajax: {
                    url: $('.select2-field.categories').data('endpoint'),
                    dataType: 'json',
                    delay: 250,
                    data: function (params) {
                        return {
                            q: params.term,
                            page: params.page
                        };
                    },
                    processResults: function (data) {
                        return {
                            results: $.map(data, function (item) {
                                return {
                                    text: item.full_path,
                                    id: item.id
                                };
                            })
                        };
                    }
                }
            });
        });
        $(function () {
            return $('.select2-field.taxonomies').select2({
                theme: 'bootstrap4',
                minimumInputLength: 3,
                maximumInputLength: 20,
                ajax: {
                    url: $('.select2-field.taxonomies').data('endpoint'),
                    dataType: 'json',
                    delay: 250,
                    data: function (params) {
                        return {
                            q: params.term,
                            page: params.page
                        };
                    },
                    processResults: function (data) {
                        return {
                            results: $.map(data, function (item) {
                                return {
                                    text: item.taxon_path,
                                    id: item.id
                                };
                            })
                        };
                    }
                }
            });
        });
        $(function () {
            return $('.select2-field.components').select2({
                theme: 'bootstrap4',
                minimumInputLength: 3,
                maximumInputLength: 20,
                ajax: {
                    url: $('.select2-field.components').data('endpoint'),
                    dataType: 'json',
                    delay: 250,
                    data: function (params) {
                        return {
                            q: params.term,
                            page: params.page
                        };
                    },
                    processResults: function (data) {
                        return {
                            results: $.map(data, function (item) {
                                return {
                                    text: item.part_number,
                                    id: item.id
                                };
                            })
                        };
                    }
                }
            });
        });
        $(function () {
            return $('.select2-field.suggestions').select2({
                theme: 'bootstrap4',
                minimumInputLength: 3,
                maximumInputLength: 20,
                ajax: {
                    url: $('.select2-field.suggestions').data('endpoint'),
                    dataType: 'json',
                    delay: 250,
                    data: function (params) {
                        return {
                            q: params.term,
                            page: params.page
                        };
                    },
                    processResults: function (data) {
                        return {
                            results: $.map(data, function (item) {
                                return {
                                    text: item.part_number,
                                    id: item.id
                                };
                            })
                        };
                    }
                }
            });
        });
        $(function () {
            return $('.select2-field.also_boughts').select2({
                theme: 'bootstrap4',
                minimumInputLength: 3,
                maximumInputLength: 20,
                ajax: {
                    url: $('.select2-field.also_boughts').data('endpoint'),
                    dataType: 'json',
                    delay: 250,
                    data: function (params) {
                        return {
                            q: params.term,
                            page: params.page
                        };
                    },
                    processResults: function (data) {
                        return {
                            results: $.map(data, function (item) {
                                return {
                                    text: item.part_number,
                                    id: item.id
                                };
                            })
                        };
                    }
                }
            });
        });
        $(function () {
            return $('.select2-field.supersedes').select2({
                theme: 'bootstrap4',
                minimumInputLength: 3,
                maximumInputLength: 20,
                ajax: {
                    url: $('.select2-field.supersedes').data('endpoint'),
                    dataType: 'json',
                    delay: 250,
                    data: function (params) {
                        return {
                            q: params.term,
                            page: params.page
                        };
                    },
                    processResults: function (data) {
                        return {
                            results: $.map(data, function (item) {
                                return {
                                    text: item.part_number,
                                    id: item.id
                                };
                            })
                        };
                    }
                }
            });
        });
    }
});
