document.addEventListener("turbolinks:load", (event) => {

    $(document).ready(function() {

        $(".clickable-rows td:not(.actions)").click(function() {
            window.document.location = $(this).parent('tr').data("href");
        });

        var popoverTriggerList = [].slice.call(document.querySelectorAll('img[data-bs-toggle="popover"]'));
        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new Popover(popoverTriggerEl, {
                trigger: 'hover',
                html: true,
                content: function () {
                    return '<img class="img-fluid" src="'+popoverTriggerEl.getAttribute("src") + '" />';
                }
            });
        });

        tippy(document.querySelectorAll('[data-tippy-content]'), {
            animation: 'shift-toward-extreme'
        });

    });

    $(event.target).find('.array-add-item').each(function() {
        $(this).on('click', e => {
            e.preventDefault();
            const inputList = this.parentElement.querySelectorAll('.input-group');
            const lastLineField = inputList[inputList.length - 1]
            const $cloneField = $(lastLineField).clone();
            $cloneField.find('input').val("");
            $cloneField.find('button').removeAttr('disabled');
            $(lastLineField).after($cloneField);
        });
    })

    $(event.target).on('click', '.array-remove-item', e => {
        e.preventDefault();
        e.currentTarget.parentElement.remove();
    });

    $(event.target).find('.video-array-add-item').each(function() {
        $(this).on('click', e => {
            e.preventDefault();
            const inputList = this.parentElement.querySelectorAll('.group');
            const lastLineField = inputList[inputList.length - 1];
            const $cloneField = $(lastLineField).clone();
            $cloneField.find('input').val("");
            $cloneField.find('input').attr("placeholder", "Video URL");
            $cloneField.find('.youtube').attr('id','');
            $cloneField.find('.youtube').attr('style','');
            $cloneField.find('.youtube').html("");
            $cloneField.find('.youtube').hide();
            $cloneField.find('.youtube').removeAttr('data-youtube-url');
            $cloneField.find('button').removeAttr('disabled');
            $cloneField.find('.form-group').removeClass('absolute bottom-0 left-0 right-0 opacity-0');
            $(lastLineField).after($cloneField);
        });
    });

    $(event.target).on('click', '.video-array-remove-item', e => {
        e.preventDefault();
        e.currentTarget.parentElement.parentElement.remove();
    });


    $(function ($) {
        var debug = false;

        function checkConditions(inputs, views) {
            if (debug) console.log('Recalculating view conditions.');

            views.each(function () {
                var view = $(this);
                var conditions = parseConditions(view.attr('data-view-conditions'));
                var display = true;

                conditions.forEach(condition => {
                    var input = getInputElement(inputs, condition.name);

                    if (!input.length) {
                        display = false;
                        logViewNotFound(debug, condition.name);
                    } else if (condition.value && condition.value.includes("|") ? condition.value.includes(input.val()) : input.val() !== condition.value) {
                        display = condition.value.includes("|");
                        if (!display) logViewValueMismatch(debug, condition.name, condition.value, input.val());
                    }
                });

                updateViewStatus(view, display);
            });
        }

        function parseConditions(conditionsStr) {
            return conditionsStr.split(',').map(conditionStr => {
                var parts = conditionStr.split(':');
                if (parts.length !== 2) {
                    console.error('Malformed condition string:', conditionStr);
                    return { name: '', value: '' };
                }
                return { name: parts[0], value: parts[1] };
            });
        }

        function getInputElement(inputs, name) {
            let selector = name.includes('[') ? `[name="${name}"]` : `#${name}`;
            let elm = inputs.filter(selector)[0];
            if (!elm) return $(); // Return empty jQuery object if element not found

            let type = elm.tagName.toLowerCase();
            return type === 'input' ? inputs.filter(`${selector}:checked`) : inputs.filter(`select${selector}`);
        }

        function logViewNotFound(debug, name) {
            if (debug) console.log(`View not shown as no input with the name "${name}" was found.`);
        }

        function logViewValueMismatch(debug, name, expectedValue, actualValue) {
            if (debug) console.log(`View not shown as the input "${name}" has the value "${actualValue}". Value needed was: "${expectedValue}".`);
        }

        function updateViewStatus(view, display) {
            if (display) {
                view.slideDown(300);
                view.find('input').prop('disabled', false);
            } else {
                view.slideUp(300, function () {
                    view.find('input').prop('disabled', true);
                });
            }
        }

        window.setFormEventHandlers = function () {
            $('form').each(function () {
                var form = $(this);
                var inputs = form.find('input[type="checkbox"], input[type="radio"], select');
                var views = form.find('[data-view-conditions]');

                inputs.off().on('change', function () {
                    checkConditions(inputs, views);
                });

                checkConditions(inputs, views);
            });
        };

        setFormEventHandlers();
    });
});

// $(document).on('turbolinks:load', () => {
//     stuff goes here
// });

