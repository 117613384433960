import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["jobList"]

  connect() {
    console .log("Connected to JobSearchController")
    this.pollJobs()
  }

  pollJobs() {
    this.searchJobs()
    setInterval(() => {
      this.searchJobs()
    }, 10000) // Poll every 10 seconds
  }

  searchJobs() {
    const productId = this.data.get("productId")

    fetch(`/products/${productId}/search_jobs`)
      .then(response => response.json())
      .then(data => {
        this.updateJobList(data.jobs)
      })
  }

  updateJobList(jobs) {
    this.jobListTarget.innerHTML = jobs.map(job => `
      <div class="job mt-2 alert alert-${job.short_status}">
         <strong>Job:</strong> ${job.name}${job.position ? `, <strong>Position:</strong> ${job.position}` : ''}, <strong>Status:</strong> ${job.status}
      </div>
    `).join("")
  }
}
